<template>
    <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
        <div class="container mx-auto">
            <h1
                class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
            >
                Add Event
            </h1>

            <div class="mb-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4">

                <form method="post" @submit.prevent="addNew">
                    <div class="flex flex-col">
                        <div class="mb-6" id="message"></div>
                        <div class="mb-4">
                        <label
                            class="block text-grey-darker text-sm font-bold mb-2"
                            for="event"
                        >
                            Event
                        </label>
                        <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                            id="name"
                            type="text"
                            placeholder="Event"
                            
                        />
                        </div>

                       
                        <div class="flex items-center justify-between">
                        <button
                            v-if="!loading"
                            class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                            type="submit"
                        >
                            Submit
                        </button>
                        <button
                            v-if="loading"
                            class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                            type="submit"
                            disabled
                        >
                            Submitting...
                        </button>
                        </div>
                    </div>
                </form>
            </div>
            <div id="recipients" >
                
            </div>
        </div>
    </main>
</template>
  
<script>

export default {

name: "AddResale",
data() {
  return {
    sidebarOpen: true,
    name: "",
    error: false,
    loading: false
  };
},
methods: {
  addNew() {
    this.error = false;

 
    
    var  nameevent;
    var html ="";
   
    


    this.loading = true;
    this.$axios
    .get(`https://app.ticketmaster.com/discovery/v2/events?keyword=${nameevent}&apikey=C1zETHjzG9nLxALw3JlbfE4kfWx7TVXW`)
    .then(res => {

        // console.log(res)

        // console.log(res.data._embedded.events.length)
       
        for (var i=0; i< res.data._embedded.events.length; i++)
        {
            html +="<table><tbody>";
            // console.log(res.data._embedded.events[i].name)
            // console.log(i)

            html +="<tr><td> "+res.data._embedded.events[i].type+" Name </td> &nbsp; <td> </td> &nbsp; <td> </td> <td>"+res.data._embedded.events[i].name+"</td></tr>";

            html +="<tr><td>Type </td> <td> </td> <td> </td> <td>"+res.data._embedded.events[i].type+"</td></tr>";

            html +="<tr><td> "+res.data._embedded.events[i].type+" URl </td> <td> </td> <td> </td> <td>"+res.data._embedded.events[i].url+"</td></tr>";

            html +="<tr><td> Sale </td> <td> Public</td> <td> Start Date & Time </td> <td>"+res.data._embedded.events[i].sales.public.startDateTime+"</td></tr>";
            
            html +="<tr><td>  </td> <td> </td> <td> End Date & Time </td> <td>"+res.data._embedded.events[i].sales.public.endDateTime+"</td></tr>";

           


            if(res.data._embedded.events[i].sales.presales)
            {

                if(res.data._embedded.events[i].sales.presales.length > 1)
                {
                    for (var j=0; j< res.data._embedded.events[i].sales.presales.length; j++)
                    {
                        html +="<tr><td>  </td> <td> Presale </td> <td> Start Date & Time </td> <td>"+res.data._embedded.events[i].sales.presales[j].startDateTime+"</td></tr>";
                
                        html +="<tr><td>  </td> <td> </td> <td> End Date & Time </td> <td>"+res.data._embedded.events[i].sales.presales[j].endDateTime+"</td></tr>";
                    }
                }
                else
                {
                    html +="<tr><td>  </td> <td> Presale </td> <td> Start Date & Time </td> <td>"+res.data._embedded.events[i].sales.presales[0].startDateTime+"</td></tr>";
                
                    html +="<tr><td>  </td> <td> </td> <td> End Date & Time </td> <td>"+res.data._embedded.events[i].sales.presales[0].endDateTime+"</td></tr>";
                    
                }
            }
            
            if(res.data._embedded.events[i].promoter)
            {

                if(res.data._embedded.events[i].promoter.length > 1)
                {      
                    for (var q=0; q< res.data._embedded.events[i].promoter.length; q++)
                    {
                        html +="<tr><td> Promoter  </td> <td> ID </td> <td>  </td> <td>"+res.data._embedded.events[i].promoter[q].id+"</td></tr>";
                
                        html +="<tr><td>  </td> <td> Name </td>  <td>  </td> <td>"+res.data._embedded.events[i].promoter[q].name+"</td></tr>";

                        html +="<tr><td>  </td> <td> Description </td>  <td>  </td> <td>"+res.data._embedded.events[i].promoter[q].description+"</td></tr>";
                    }
                }
                else
                {
                    html +="<tr><td> Promoter  </td> <td> ID </td> <td>  </td> <td>"+res.data._embedded.events[i].promoter.id+"</td></tr>";
                
                    html +="<tr><td>  </td> <td> Name </td>  <td>  </td> <td>"+res.data._embedded.events[i].promoter.name+"</td></tr>";

                    html +="<tr><td>  </td> <td> Description </td>  <td>  </td> <td>"+res.data._embedded.events[i].promoter.description+"</td></tr>";
                    
                }
            }

            if(res.data._embedded.events[i].promoters)
            {

                if(res.data._embedded.events[i].promoters.length > 1)
                {      
                    for (var w=0; w< res.data._embedded.events[i].promoters.length; w++)
                    {
                        html +="<tr><td> Promoters  </td> <td> ID </td> <td>  </td> <td>"+res.data._embedded.events[i].promoters[w].id+"</td></tr>";
                
                        html +="<tr><td>  </td> <td> Name </td>  <td>  </td> <td>"+res.data._embedded.events[i].promoters[w].name+"</td></tr>";

                        html +="<tr><td>  </td> <td> Description </td>  <td>  </td> <td>"+res.data._embedded.events[i].promoters[w].description+"</td></tr>";
                    }
                }
                else
                {
                    html +="<tr><td> Promoters  </td> <td> ID </td> <td>  </td> <td>"+res.data._embedded.events[i].promoters[0].id+"</td></tr>";
                
                    html +="<tr><td>  </td> <td> Name </td>  <td>  </td> <td>"+res.data._embedded.events[i].promoters[0].name+"</td></tr>";

                    html +="<tr><td>  </td> <td> Description </td>  <td>  </td> <td>"+res.data._embedded.events[i].promoters[0].description+"</td></tr>";
                    
                }
            }


            if(res.data._embedded.events[i].priceRanges)
            {

                if(res.data._embedded.events[i].priceRanges.length > 1)
                {      
                    for (var e=0; e< res.data._embedded.events[i].priceRanges.length; e++)
                    {
                        
                        html +="<tr><td> Price Ranges  </td> <td> Type </td> <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[e].type+"</td></tr>";
                
                        html +="<tr><td>  </td> <td> Currency </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[e].currency+"</td></tr>";

                        html +="<tr><td>  </td> <td> Minimum </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[e].min+"</td></tr>";

                        html +="<tr><td>  </td> <td> Maximum </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[e].max+"</td></tr>";
                    }
                }
                else
                {
                    // console.log(res.data._embedded.events[i].priceRanges[0].type)
                    html +="<tr><td> Price Ranges  </td> <td> Type </td> <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[0].type+"</td></tr>";
                
                    html +="<tr><td>  </td> <td> Currency </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[0].currency+"</td></tr>";

                    html +="<tr><td>  </td> <td> Minimum </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[0].min+"</td></tr>";

                    html +="<tr><td>  </td> <td> Maximum </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[0].max+"</td></tr>";
                    
                }
            }

            // if(res.data._embedded.events[i].products)
            // {

            //     if(res.data._embedded.events[i].priceRanges.length > 1)
            //     {      
            //         for (var e=0; e< res.data._embedded.events[i].priceRanges.length; e++)
            //         {
                        
            //             html +="<tr><td> Price Ranges  </td> <td> Type </td> <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[e].type+"</td></tr>";
                
            //             html +="<tr><td>  </td> <td> Currency </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[e].currency+"</td></tr>";

            //             html +="<tr><td>  </td> <td> Minimum </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[e].min+"</td></tr>";

            //             html +="<tr><td>  </td> <td> Maximum </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[e].max+"</td></tr>";
            //         }
            //     }
            //     else
            //     {
            //         // console.log(res.data._embedded.events[i].priceRanges[0].type)
            //         html +="<tr><td> Price Ranges  </td> <td> Type </td> <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[0].type+"</td></tr>";
                
            //         html +="<tr><td>  </td> <td> Currency </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[0].currency+"</td></tr>";

            //         html +="<tr><td>  </td> <td> Minimum </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[0].min+"</td></tr>";

            //         html +="<tr><td>  </td> <td> Maximum </td>  <td>  </td> <td>"+res.data._embedded.events[i].priceRanges[0].max+"</td></tr>";
                    
            //     }
            // }











            html +="</tbody></table>";
        }
        document.getElementById("recipients").innerHTML = html;



        // html += "<table id='tn-tickets' class='border-collapse w-full text-sm'><tr><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>status</th><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>resaleStatus</th>";
        // //res.data._embedded.events.length
        // if(res.data._embedded.events.length !=0)
        // {
        //     for(var i=0; i<res.data._embedded.events.length; i++)
        //     {
                
        //         // fetch(`https://app.ticketmaster.com/inventory-status/v1/availability?events=${res.data._embedded.events[i].id}&apikey=ZsLopCvVOOwux0FbAYJyXK7ibNufhpwO`, {
        //         //     headers: {
        //         // "Access-Control-Allow-Origin": "*",
        //         // //   'Content-Type': 'application/json',
        //         // },
        //         // mode: 'no-cors',
        //         // })
        //         // .then(response => {
        //             // console.log(response.eventId)
        //             // console.log(response)
        //                 html += '<tr>';
        //                 html += '<td>FEW_TICKETS_LEFT</td><td>TICKETS_AVAILABLE</td>';
        //                 html += '</tr>';
                        
        //         // })
        //         // .catch(error => console.log('error', error));
        //     }
                
        //     // html += "</tbody></table>";
        //     document.getElementById("recipients").innerHTML = html;
        // }
        this.loading = false;
    })
    .catch(err => {
    this.loading = false;
    console.log(err);
    document.getElementById("message").innerHTML = `
        <span style="color:#a94442;font-weight:bold">Something went wrong</span>
        `;
    });
    
    setTimeout(() => {
      document.getElementById("message").innerHTML = ``;
    }, 6000);
  }
}
};

</script>
  